<template>
  <div></div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  components: {},
  name: 'Development',
  data() {
    return {};
  },
  methods: {},
});
</script>
